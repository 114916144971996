<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
			}
		},
		async mounted() {
			await this.ready();
            this.$set(this.$root, 'page', this);
		},
	};
</script>
<template>
	<div>
		<section id="content" style="overflow: visible">
		    <div class="content-wrap p_0">
		        <div class="clear"></div>
		        <div class="section   notopmargin nobottommargin">
		            <div class="container clearfix">
		                <div class="row justify-content-center">
		                    <div class="col-md-10 center">
		                        <div class="heading_section">
		                            <h2>Kontak Kami</h2>
		                            <p>
		                                Tim kami akan dengan senang hati menjawab pertanyaan yang Anda Berikan.
		                            </p>
		                        </div>
		                    </div>
		                </div>
		                <div class="row justify-content-center">
		                    <div class="col-lg-6">
		                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.550185445493!2d106.70153199986039!3d-6.190890824756585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f9c90a24fa6d%3A0x1dca2ab70a3a440d!2sRuko%20Crown%20Green%20Lake%20City!5e0!3m2!1sen!2sid!4v1587368879826!5m2!1sen!2sid" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
		                    </div>
		                    <div class="col-md-4 mt_33">
		                        <div class="office_address">
		                            <h2>Alamat Kantor</h2>
		                            <p>Yayasan Visi Viyata Lestari<br>
		                                Green lake City Rukan Crown Blok K No.19<br>
		                            Cipondoh - Kota Tangerang 15147</p>
		                            <h3>Telepon: 0812-1256-3387</h3>
		                            <h3>Email: info@visiviyatalestari.org</h3>
		                        </div>
		                    </div>
		                </div>
		            </div>
		        </div>
		        <div class="clear"></div>
		    </div>
		</section>
	</div>
</template>